import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ich liebe Karussells! Die bunten Freizeitpark-Attraktionen mit Tierfiguren und Wägelchen sind oft wunderschön gestaltet.
Kinder können Stunden über Stunden damit verbringen und sich im Kreis drehen. Die pure Freude!`}</p>
    <p>{`Kann ich dasselbe über Karussell-UI-Komponenten sagen? Nicht wirklich. Ich mag die meisten Web-Karussells nicht. Vor allem,
wenn sie automatisch den Inhalt wechseln. Außerdem sind die meisten von ihnen ein Accessibility-Albtraum. Also habe ich mir
gedacht: Das geht doch besser!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAD/9oADAMBAAIQAxAAAAF5RUH5TBS//8QAGRAAAgMBAAAAAAAAAAAAAAAAAAECAxES/9oACAEBAAEFAoSROzRorQ3h0f/EABYRAAMAAAAAAAAAAAAAAAAAABARIf/aAAgBAwEBPwFQf//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAECAQE/Aaf/xAAZEAEAAgMAAAAAAAAAAAAAAAAAASEQETH/2gAIAQEABj8C3C1pcx//xAAaEAACAgMAAAAAAAAAAAAAAAABEQAhMVFh/9oACAEBAAE/ITNwDNw+AF4hCbX2LuXaiXAUJvU//9oADAMBAAIAAwAAABCvL//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QF0zl/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhMf/aAAgBAgEBPxBeC2//xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMUFh8P/aAAgBAQABPxDsRtVpW7NcMEDfEDAa+Y9crqzUqaA5niwhoJsRqS/Az//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Karussell bei Nacht mit hellen Lichtern. Die Figuren eines Pferdes und eines Tigers sind zu sehen.",
          "title": "Ein Karussell bei Nacht mit hellen Lichtern. Die Figuren eines Pferdes und eines Tigers sind zu sehen.",
          "src": "/static/6fdef0142ea1010d50437c1db21c7725/e5166/pexels-alexander-nadrilyanski-carousel.jpg",
          "srcSet": ["/static/6fdef0142ea1010d50437c1db21c7725/f93b5/pexels-alexander-nadrilyanski-carousel.jpg 300w", "/static/6fdef0142ea1010d50437c1db21c7725/b4294/pexels-alexander-nadrilyanski-carousel.jpg 600w", "/static/6fdef0142ea1010d50437c1db21c7725/e5166/pexels-alexander-nadrilyanski-carousel.jpg 1200w", "/static/6fdef0142ea1010d50437c1db21c7725/b17f8/pexels-alexander-nadrilyanski-carousel.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Alexander Nadrilyanski / pexels.com`}</em></p>
    <p>{`Ich habe ein `}<a parentName="p" {...{
        "href": "https://github.com/alexlehner86/accessible-news-carousel"
      }}>{`News-Karussell`}</a>{` als
`}<a parentName="p" {...{
        "href": "https://angular.io/guide/standalone-components"
      }}>{`eigenständige Angular-Komponente`}</a>{` erstellt. Die Komponente ist
barrierefrei, responsiv und bietet flüssige Animationen. Werfen wir einen Blick darauf, wie es aufgebaut ist und funktioniert.`}</p>
    <h2>{`Demo: Barrierefreies News-Karussell`}</h2>
    <p>{`Meine `}<a parentName="p" {...{
        "href": "https://alexlehner86.github.io/accessible-news-carousel/"
      }}>{`Demo-Anwendung`}</a>{` zeigt eine Liste von Nachrichten an, jede
mit Überschrift, einem Textausschnitt und einem Hintergrundbild. Die Nutzer:innen können sich mit Wischgesten oder den
Navigations-Schaltflächen von Nachricht zu Nachricht bewegen.`}</p>
    <iframe src="https://alexlehner86.github.io/accessible-news-carousel/" title="Demo-Seite: Barrierefreies News-Karussell" loading="lazy"></iframe>
    <h2>{`Anforderungen an Design und Barrierefreiheit`}</h2>
    <p>{`Es gibt kein natives HTML-Element für Web-Karussells. Deshalb gibt es so viele verschiedene Implementierungen. Zumindest
hat das W3C in seinem „ARIA Authoring Practices Guide“ grundlegende Anforderungen definiert. Das
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/carousel/"
      }}>{`Karussell-Pattern`}</a>{` lautet:`}</p>
    <blockquote lang="en">
    A <BoldText mdxType="BoldText">carousel</BoldText> presents a set of items, referred to as slides, by sequentially displaying a subset
    of one or more slides. Typically, one slide is displayed at a time, and users can activate a next or previous slide
    control that hides the current slide and “rotates” the next or previous slide into view.
    </blockquote>
    <p>{`Diese abstrakte Definition gilt sowohl für einfache Bild-Rotatoren als auch für Diashows mit komplexem Inhalt. Im
Allgemeinen sollten Web-Karussells die folgenden Anforderungen erfüllen:`}</p>
    <ol>
      <li parentName="ol">{`Es sollte assistiven Technologien seine Struktur vermitteln.`}</li>
      <li parentName="ol">{`Es sollte den aktuell aktiven Inhalt anzeigen, sowohl visuell als auch für assistive Technologien.`}</li>
      <li parentName="ol">{`Die Nutzer:innen sollten nicht gezwungen sein, das
`}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/pointer-gestures.html"
        }}>{`Karussell mit Wischgesten zu bedienen`}</a>{`. Es sollte
simple Steuerelemente bieten, die man anklicken oder mit der Tastatur bedienen kann.`}</li>
      <li parentName="ol">{`Screenreader-Nutzer:innen sollten über dynamische Inhaltsänderungen (z.B. der Wechsel zur nächsten Slide) mittels
`}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/status-messages.html"
        }}>{`Statusmeldung`}</a>{` informiert werden.`}</li>
      <li parentName="ol">{`Wenn das Karussell den angezeigten Inhalt automatisch wechselt, sollte es auch eine Schaltfläche zum Anhalten und
erneuten Starten der Rotation haben.`}</li>
    </ol>
    <p>{`Ich hasse Bewegungen und Animationen, die automatisch starten. Deshalb wechselt der angezeigte Inhalt in meinem
News-Karussell nicht von selbst. Somit braucht es auch keine Pausentaste.`}</p>
    <h2>{`Struktur und semantische Auszeichnung`}</h2>
    <h3>{`Der Karussell-Container`}</h3>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-1.2/"
      }}>{`ARIA-Spezifikation`}</a>{` enthält keine `}<InlineCode mdxType="InlineCode">{`carousel`}</InlineCode>{` Rolle.
Daher müsst ihr `}<InlineCode mdxType="InlineCode">{`role="region"`}</InlineCode>{` definieren und die benutzerdefinierte
Beschreibung `}<InlineCode mdxType="InlineCode">{`aria-roledescription="Karussell"`}</InlineCode>{` bereitstellen. Ein verständliches Label wird
durch das Attribut `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` bereitgestellt.`}</p>
    <p>{`In meiner `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{` nutze ich `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/HostBinding"
      }}>{`HostBinding`}</a>{`,
um diese Werte für den Container der Karussell-Komponente zu setzen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`/**
 * The aria label for the carousel container.
 * It should convey to screenreader users what the carousel is about.
 */
@HostBinding('attr.aria-label') @Input() public carouselLabel!: string;
@HostBinding('attr.role') role = 'region';
@HostBinding('attr.aria-roledescription') get carouselDescription() { return this.config.carouselDescription; }`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das Karussell enthält zwei Icon-Schaltflächen, um zur vorherigen oder nächsten Slide zu navigieren.
Programmatisch ermittelbare Labels werden mit dem Attribut `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` für
die `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Tags definiert.`}</p>
    <h3>{`Der Slide-Container`}</h3>
    <p>{`Für jeden Slide-Container ist `}<InlineCode mdxType="InlineCode">{`role="group"`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`aria-roledescription="Slide"`}</InlineCode>{`
gesetzt. Das Attribut `}<InlineCode mdxType="InlineCode">{`aria-label`}</InlineCode>{` des Containers gibt die Nummer der Slide und die
Gesamtanzahl an (z.B. „2 von 5“).`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div
    *ngFor="let item of newsItems; let i = index"
    class="news-item"
    role="group"
    [attr.aria-roledescription]="config.slideDescription"
    [attr.aria-label]="(i + 1) + ' ' + config.slideLabel + ' ' + newsItems.length"
>
    <!-- slide content -->
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Der Nachteil von benutzerdefinierten Rollenbeschreibungen ist, dass sie nicht automatisch von Screenreadern übersetzt
werden. Wenn ihr eine mehrsprachige Website erstellt, müsst ihr dies berücksichtigen.
Meine `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{` hat einen Input-Wert `}<InlineCode mdxType="InlineCode">{`config`}</InlineCode>{` mit
Standardwerten in Englisch, die überschrieben werden können.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@Input() public config: NewsCarouselConfig = {
    carouselDescription: 'carousel',
    slideDescription: 'slide',
    slideLabel: 'of',
    nextButtonLabel: 'Next slide',
    previousButtonLabel: 'Previous slide',
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Vermeide Listen-Elemente`}</h3>
    <p>{`Häufig werden Slides als Liste ausgezeichnet. Screenreader lesen die Anzahl der Elemente in einer Liste vor,
ignorieren aber ausgeblendete Listenelemente. In meiner Komponente ist nur die sichtbare Slide auch für assistive
Technologien zugänglich. Die anderen Slides werden visuell und programmatisch mit der
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`visibility: hidden`}</InlineCode>{` verborgen.`}</p>
    <p>{`Wenn ich alle Slides als `}<InlineCode mdxType="InlineCode">{`<`}{`li`}{`>`}</InlineCode>{`-Elemente innerhalb einer ungeordneten Liste
(`}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{`) implementiert hätte, würde der Screenreader das Vorhandensein einer Liste mit
nur einem Element verkünden. Das entspricht nicht der tatsächlichen Anzahl an Slides und wäre für die
Nutzer:innen verwirrend.`}</p>
    <h3>{`ARIA Live-Region`}</h3>
    <p>{`Das Karussell sollte dynamische Inhaltsänderungen über Statusmeldungen an Screenreader-Nutzer:innen kommunizieren.
Zunächst habe ich versucht, das Container-Element für alle Slides zur
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions"
      }}>{`ARIA Live-Region`}</a>{`
zu machen. Das Ergebnis war nicht optimal.`}</p>
    <p>{`Aufgrund der Implementierung der Slide-Animation mit Hilfe der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`visibility`}</InlineCode>{`
las der Screenreader die Überschrift der neuen Slide mehrmals vor oder las die ausgeblendete Slide erneut vor.`}</p>
    <p>{`Ich habe verschiedene Ansätze mit Eigenschaften wie `}<InlineCode mdxType="InlineCode">{`aria-busy`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`aria-relevant`}</InlineCode>{`
ausprobiert. Sie funktionierten immer nur bei einigen Browser- und Screenreader-Kombinationen. Daher entschied ich
mich schließlich für den einfachen Ansatz, eine `}<a parentName="p" {...{
        "href": "https://webaim.org/techniques/css/invisiblecontent/"
      }}>{`visuell verborgene`}</a>{`
ARIA Live-Region an das Ende des Karussell-Containers zu setzen. Das ist zwar ein bisschen redundant, aber es funktioniert!`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div
    class="active-slide-live-region"
    aria-live="polite"
>
    {{config.slideDescription + ' ' + (activeSlideIndex + 1) + ' ' + config.slideLabel + ' ' + newsItems.length + ':'}}
    {{newsItems[activeSlideIndex].heading}}
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Tastaturbedienung und Swipe-Gesten`}</h2>
    <p>{`Ich liebe wischen! Wenn ich online auf ein Karussell stoße, versuche ich instinktiv, über den Bildschirm zu wischen.
Swipe-Gesten an sich sind nicht böse. Aber ein Web-Karussell, das nur durch Wischgesten bedienbar ist, schon!`}</p>
    <p>{`Für viele Menschen mit motorischer Beeinträchtigung sind Wischgesten nicht barrierefrei. Sie bevorzugen
`}<a parentName="p" {...{
        "href": "https://www.accessguide.io/guide/single-pointer-gestures"
      }}>{`einfach klickbare Alternativen`}</a>{` wie eine Schaltfläche. Außerdem
benötigen Tastatur-Nutzer:innen Steuerelemente, die sie mit der Tabulatortaste ansteuern und mit der Leer- oder Eingabetaste
aktivieren können.`}</p>
    <p>{`Deshalb versuche ich, alle glücklich zu machen! Meine Angular-Komponente implementiert Wischgesten mit
der `}<a parentName="p" {...{
        "href": "https://hammerjs.github.io/"
      }}>{`Hammer.js-Bibliothek`}</a>{` und enthält auch Schaltflächen, um zur vorherigen oder nächsten
Slide zu navigieren.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="news-carousel-controls">
    <button
        type="button"
        [attr.aria-label]="config.previousButtonLabel"
        (click)="this.showPreviousSlide()"
    >
        <!-- arrow icon -->
    </button>
    <button
        type="button"
        [attr.aria-label]="config.nextButtonLabel"
        (click)="this.showNextSlide()"
    >
        <!-- arrow icon -->
    </button>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Slide-Übergänge mit CSS-Animationen`}</h2>
    <p>{`Ich wollte schöne Übergänge zwischen den Slides mit sanften Ein- und Ausblend-Animationen erstellen. Angular bietet ein
eigenes `}<a parentName="p" {...{
        "href": "https://angular.io/guide/animations"
      }}>{`Animationsmodul`}</a>{`. Mir war jedoch wichtig, die Build-Größe klein zu halten
und gleichzeitig effizient zu sein. Deshalb habe ich mich für
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations"
      }}>{`CSS-Animationen`}</a>{` entschieden.`}</p>
    <p>{`Das Erstellen von Animationen mit CSS ist super einfach und elegant. Zuerst definiert ihr Keyframes für eure benannten
Animationen. Jeder Keyframe beschreibt, wie das animierte Element zu einem bestimmten Zeitpunkt während der
Animationssequenz dargestellt werden soll.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`// Keyframes for slide animation from left to right
@keyframes slide-in-from-left {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@keyframes slide-out-to-right {
    0% {
        transform: translateX(0%);
        visibility: visible;
    }
    100% {
        transform: translateX(100%);
        visibility: hidden;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Dann wendet ihr diese Animationen mithilfe der CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`animation`}</InlineCode>{` auf bestimmte
Slides an. Auf diese Weise könnt ihr eine Vielzahl von Animations-Eigenschaften (z.B. Name, Dauer, Richtung)
in einer einzigen CSS-Regel definieren.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`app-news-carousel .news-carousel-items.animate-from-left .news-item {
    &.active {
        animation: slide-in-from-left 0.5s forwards;
    }

    &.moved-out {
        animation: slide-out-to-right 0.5s forwards;
        border-left: 0.125rem solid white;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`In dem obigen Beispiel werden zwei HTML-Elemente animiert: Das Element mit der Klasse `}<InlineCode mdxType="InlineCode">{`active`}</InlineCode>{`
gleitet von links ins Bild, während das andere Element mit der Klasse `}<InlineCode mdxType="InlineCode">{`moved-out`}</InlineCode>{` nach rechts
aus dem Bild gleitet.`}</p>
    <h2>{`Responsives Design und benutzerdefiniertes Styling`}</h2>
    <h3>{`Flexibles Layout mit `}<InlineCode mdxType="InlineCode">{`max-width`}</InlineCode>{` und Container Queries`}</h3>
    <p>{`Wenn ihr die Karussell-Komponente verwendet, könnt ihr die CSS-Variable `}<InlineCode mdxType="InlineCode">{`--news-carousel-max-width`}</InlineCode>{`
setzen, um eine Basisbreite für den Karussell-Container zu definieren. Wenn keine Variable gesetzt ist, wird der
Fallback-Wert der `}<InlineCode mdxType="InlineCode">{`var()`}</InlineCode>{`-Funktion verwendet.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`app-news-carousel {
    height: var(--news-carousel-height, 25rem);
    width: var(--news-carousel-max-width, 50rem);
    max-width: 100%;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Mithilfe von `}<InlineCode mdxType="InlineCode">{`max-width: 100%`}</InlineCode>{` stellen wir sicher, dass die Breite des Containers durch den
verfügbaren Raum seines übergeordneten Elements begrenzt wird. Auf diese Weise beansprucht das Karussell nie mehr Platz
als die Viewport-Breite des Geräts.`}</p>
    <p>{`Außerdem verwende ich `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries"
      }}>{`CSS Container Queries`}</a>{`,
um das Styling des Karussells anzupassen, wenn die Breite des Containers unter einem bestimmten Schwellenwert liegt.
Dieses neue, mächtige Feature wird bald `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-container-queries"
      }}>{`von allen modernen Browsern unterstützt`}</a>{`
werden. Ich werde in einem separaten Blog-Beitrag näher darauf eingehen.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@container news-carousel (max-width: 28rem) {
    app-news-carousel .news-item .slide-text {
        bottom: 0.5rem;
        border-radius: 0.5rem;
        width: calc(100% - 1rem);

        p {
            display: none;
        }
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Eigenes Styling mit CSS-Variablen`}</h3>
    <p>{`CSS-Variablen sind ideal für Komponenten-Bibliotheken. Sie können ein Standard-Styling definieren und den Nutzer:innen
erlauben, bestimmte Werte mit den entsprechenden Variable zu überschreiben. Eine Applikation kann die folgenden Styles
meiner `}<InlineCode mdxType="InlineCode">{`NewsCarouselComponent`}</InlineCode>{` über CSS-Variablen anpassen:`}</p>
    <ul>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-height</InlineCode>: Die Höhe des Karussells.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-max-width</InlineCode>: Legt die maximale Breite des Karussells fest. Die tatsächliche Breite des Karussells ist abhängig von seinem Container.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-button-background</InlineCode>: Der Hintergrund (Farbe) der Navigations-Schaltflächen.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-button-color</InlineCode>:  Die Farbe des Pfeilsymbols in den Navigations-Schaltflächen.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--carousel-text-background</InlineCode>: Der Hintergrund (Farbe) des Textcontainers der Slides.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--carousel-text-color</InlineCode>: Die Schriftfarbe des Textcontainers der Slides.
      </li>
      <li parentName="ul">
        <InlineCode mdxType="InlineCode">--news-carousel-slide-focus-color</InlineCode>: Die Farbe des Fokusindikators der Slides.
      </li>
    </ul>
    <h2>{`Was es noch zu sagen gibt`}</h2>
    <p>{`Ich möchte eines klarstellen: Meine Karussell-Komponente stellt nur eine von mehreren Möglichkeiten dar, ein
barrierefreies, responsives Karussell zu implementieren. Natürlich bin ich davon überzeugt, dass meine Implementierung
einfach großartig ist. Aber natürlich gibt es auch andere valide Ansätze.`}</p>
    <p>{`Web-Standards, Browser und assistive Technologien entwickeln sich ständig weiter. In ein paar Jahren oder vielleicht
sogar in ein paar Monaten könnte es also eine bessere Lösung geben. Ich kann es kaum erwarten, sie zu sehen! 😊`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/"
        }}>{`ARIA Authoring Practices Guide (APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-1.2/"
        }}>{`ARIA 1.2 specification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://angular.io/guide/standalone-components"
        }}>{`Angular standalone components`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Using_CSS_animations"
        }}>{`CSS Animations`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Container_Queries"
        }}>{`CSS Container Queries`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      